<template>
	<div>
		<div class="list" v-if="list.length > 0">
			<div class="item" v-for="(item,index) in list" :key="index">
				<div class="con-1">
					<van-image
					  width="100%"
					  height="100%"
					  :src="item.thumb_pic"
					/>
				</div>
				<div class="con-2" >
					<div class="title">
						{{item.title}}
					</div>
					<div class="time">
						{{item.created_at}}
					</div>
				</div>
				<div class="con-3">
					{{item.coin_num}}K币
				</div>
			</div>
		</div>
		<van-empty description="暂无记录" v-else/>
	</div>

</template>

<script>
	export default {
		created() {
			axios.get(
				"coin_records"
			).then(res => {
				if(res.code == 1){
					this.list = res.data
					console.log(this.list)
				}else{
					this.$toast(res.message)
				}
			})
		},
		data(){
			return{
				list:[]
			}
		}
	}
</script>

<style scoped lang="less">
	.list{
		padding: 20px 10px;
		box-sizing: border-box;
		.item{
			width: 100%;
			display: flex;
			height: 60px;
			margin-bottom: 15px;
			padding-bottom: 15px;
			border-bottom: 1px solid #EAEAEA;
			.con-1{
				width: 60px;
				height: 100%;
				margin-right: 15px;
			}
			.con-2{
				width: 50%;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				.title{
					font-size: 15px;
					color: #353334;
					font-weight: 700;
				}
				.time{
					font-size: 13px;
					color: #999999;
				}
			}
			.con-3{
				width: calc(100% - 75px - 50%);
				display: flex;
				align-items: center;
				justify-content: flex-end;
				color:#E04106;
				font-weight: 700;
				font-size: 14px;
			}
		}
	}
</style>