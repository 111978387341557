import { render, staticRenderFns } from "./KCoinDetail.vue?vue&type=template&id=5fc2c914&scoped=true&"
import script from "./KCoinDetail.vue?vue&type=script&lang=js&"
export * from "./KCoinDetail.vue?vue&type=script&lang=js&"
import style0 from "./KCoinDetail.vue?vue&type=style&index=0&id=5fc2c914&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc2c914",
  null
  
)

export default component.exports